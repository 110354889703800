import styled from 'styled-components';

const RecommendedWrapper = styled.div`
    margin-bottom: 10px;
`;

const Header = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #0D4270;
    padding: 15px 0;
`;

const MainContainer = styled.div``;

const HealthContainer = styled.div`
    padding: 10px;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
    background: #FFFFFF;
    border-radius: 6px;
    .img{
        width: 25%;
    }
    .content{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #0D4270;
        display: flex;
        flex-direction: column;
        width: 65%;
        justify-content: center;
        >span{
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #93A987;
        }
    }
    .arrow{
        display: flex;
        width: 10%;
        justify-content: center;
        align-items: center;
    }
`;

const BiometricContainer = styled.div`
    padding: 10px;
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 6px;
    .img{
        width: 25%;
    }
    .content{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #0D4270;
        display: flex;
        flex-direction: column;
        width: 65%;
        justify-content: center;
        >span{
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #93A987;
        }
    }
    .arrow{
        display: flex;
        width: 10%;
        justify-content: center;
        align-items: center;
    }
`;


export { RecommendedWrapper, Header, MainContainer, HealthContainer, BiometricContainer };