/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RecommendedWrapper, Header, MainContainer, HealthContainer, BiometricContainer } from './styles'
import { getListOfThingsToComplete } from '../../redux/actions';

class RecommendedForYou extends React.Component{

  componentDidMount() {
    const { getListOfThingsToComplete } = this.props;
    getListOfThingsToComplete();
  }

  handleClick = ( history, data ) => {
    if(data==="HRA"){
      history.push("/wellness-assessment");
    }else if(data==="BIO"){
      history.push("/wellness-assessment");
    }
  }
  render(){
    const { recommendedThings, history } = this.props;
    let temp = false;
    if( recommendedThings != undefined &&  recommendedThings.first_wba_completed === true ){
      temp = true;
    }else if(recommendedThings != undefined && recommendedThings.select_goals === true){
      temp = true;
    }

    return(<div>
      { temp && <RecommendedWrapper>
        <Header>
          Recommended For You
        </Header>
        <MainContainer>
          {recommendedThings && recommendedThings.first_wba_completed && <HealthContainer onClick = { ()=>this.handleClick(history, "HRA") }>
            <div className="img">
              <img src = "/images/social/HealthComponent.png" />
            </div>
            <div className="content">
              Health Risk Assessment<br/>
              <span>+25 pts</span>
            </div>
            <div className="arrow">
              <img src = "/images/social/RecommendedArrow.png" />
            </div>
          </HealthContainer>}
          { recommendedThings && recommendedThings.select_goals && <BiometricContainer onClick = { ()=>this.handleClick(history, "BIO") }>
            <div className="img">
              <img src = "/images/social/BiometricComponent.png" />
            </div>
            <div className="content">
              Complete Biometric<br/>
              Screening<br/>
              <span>+125 pts</span>
            </div>
            <div className="arrow">
              <img src = "/images/social/RecommendedArrow.png" />
            </div>
          </BiometricContainer>}
        </MainContainer>
      </RecommendedWrapper>}
    </div>
    ); 
  }
}

RecommendedForYou.propTypes = {
  getListOfThingsToComplete : PropTypes.func ,
  recommendedThings : PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = (state) => ({
  recommendedThings: state.social.recommendedListStatus
});

const mapDispatchToProps = (dispatch) => ({
  getListOfThingsToComplete : () => dispatch(getListOfThingsToComplete())
})

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedForYou)